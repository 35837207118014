@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v17/JTURjIg1_i6t8kCHKm45_cJD7g4.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v17/JTUSjIg1_i6t8kCHKm45xW4.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem8YaGs126MiZpBA-U1Ug.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN7rg-VQ.ttf) format('truetype');
}
body a:focus,
body a:hover {
  text-decoration: none;
}
img {
  width: 100%;
}
.navbar-toggle {
  display: inline-block;
  margin-top: 18px;
}
@media only screen and (min-width: 1024px) {
  .navbar-toggle {
    display: none;
  }
}
.navbar-toggle .icon-bar {
  background-color: #1F4575;
}
header {
  z-index: 4;
  position: relative;
}
header .btn-group.open .dropdown-toggle {
  box-shadow: none;
}
@media only screen and (min-width: 1024px) {
  header .dropdown.open {
    position: static;
  }
}
header .dropdown.open .caret {
  transform: rotate(180deg);
}
nav {
  width: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.8);
}
@media only screen and (min-width: 1024px) {
  nav {
    display: flex;
    position: fixed;
    top: 15px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
nav #logo_navbutton_container {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  nav #logo_navbutton_container {
    display: flex;
    width: auto;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
@media only screen and (min-width: 1024px) {
  nav #logo_navbutton_container #header_logo {
    width: 15vw;
    margin-left: 76px;
  }
}
nav #logo_navbutton_container #header_logo img {
  width: 100%;
}
@media only screen and (max-width: 1023px) {
  nav #logo_navbutton_container #header_logo img {
    width: 200px;
    margin-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
@media only screen and (max-width: 1023px) {
  nav #mobile_menu {
    padding: 0;
    text-align: center;
    font-size: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  nav #mobile_menu {
    display: flex;
    width: 75%;
    justify-content: space-around;
    margin-bottom: 0;
    height: 91px;
  }
}
@media only screen and (min-width: 1024px) {
  nav #mobile_menu .navdd.open .dropdown-menu {
    margin-top: 0;
    display: flex;
    left: 0;
    width: 100%;
    align-items: center;
    height: 21px;
    top: initial;
    bottom: -32px;
    width: 100vw;
    margin: 0;
    padding: 19px;
    justify-content: center;
    background-color: #3B99C7;
  }
}
@media only screen and (min-width: 1024px) {
  nav #mobile_menu .navdd.open .dropdown-menu li {
    border-bottom: none;
    border-right: 1px solid #c6c6c6;
    height: auto;
  }
}
@media only screen and (min-width: 1024px) {
  nav #mobile_menu .navdd.open .dropdown-menu li:last-of-type {
    border-right: none;
  }
}
@media only screen and (min-width: 1024px) {
  nav #mobile_menu .navdd.open .dropdown-menu li a {
    color: #fff;
  }
}
nav #mobile_menu .navdd.open .dropdown-menu li a:after {
  content: " ";
  opacity: 0;
}
nav #mobile_menu .navdd.open .dropdown-menu li a:hover,
nav #mobile_menu .navdd.open .dropdown-menu li a.active {
  background-image: none;
  text-decoration: none;
  color: #204675;
  background-color: #e8e8e8;
}
nav #mobile_menu .navdd.open .dropdown-menu li a:hover:after,
nav #mobile_menu .navdd.open .dropdown-menu li a.active:after {
  opacity: 0;
}
nav #mobile_menu .navdd.open .dropdown-menu li a:focus:after {
  opacity: 0;
}
nav #mobile_menu li {
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
}
@media only screen and (max-width: 1023px) {
  nav #mobile_menu li {
    justify-content: center;
  }
}
nav #mobile_menu li .dropdown-menu {
  width: 100%;
  border-radius: 0;
  background-color: #fff;
}
@media only screen and (min-width: 768px) {
  nav #mobile_menu li .dropdown-menu {
    margin-top: 29px;
    border: none;
    border-radius: 0;
    width: auto;
    background: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 1024px) {
  nav #mobile_menu li .dropdown-menu {
    top: 62px;
    padding-bottom: 0;
  }
}
nav #mobile_menu li .dropdown-menu li {
  border-bottom: 1px solid #c6c6c6;
}
nav #mobile_menu li .dropdown-menu li a {
  text-transform: capitalize;
}
nav #mobile_menu li a {
  color: #3d3d3d;
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
}
@media only screen and (min-width: 1024px) {
  nav #mobile_menu li a {
    width: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  nav #mobile_menu li a {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1600px) {
  nav #mobile_menu li a {
    font-size: 21px;
    font-weight: 400;
  }
}
@media only screen and (max-width: 1023px) {
  nav #mobile_menu li a {
    width: 160px;
    text-align: left;
  }
}
@media only screen and (max-width: 768px) {
  nav #mobile_menu li a {
    text-align: center;
  }
}
nav #mobile_menu li a:after {
  content: " ";
  width: 90%;
  position: absolute;
  bottom: -3px;
  left: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
nav #mobile_menu li a:hover {
  background-image: none;
  text-decoration: none;
}
nav #mobile_menu li a:hover:after {
  border-bottom: 5px solid #4898c7;
  bottom: -13px;
  opacity: 1;
}
nav #mobile_menu li a:focus:after {
  border-bottom: 5px solid #4898c7;
  bottom: -13px;
  opacity: 1;
}
nav #mobile_menu li a.active:after {
  border-bottom: 5px solid #4898c7;
  bottom: -13px;
  opacity: 1;
}
nav #mobile_menu li div {
  width: 2.9vw;
  height: 1.9vw;
  overflow: hidden;
  display: inline-block;
  position: relative;
  margin-right: 7px;
}
@media only screen and (max-width: 1023px) {
  nav #mobile_menu li div {
    width: 45px;
    height: 45px;
  }
}
@media only screen and (min-width: 1300px) {
  nav #mobile_menu li div {
    width: 3.2vw;
  }
}
nav #mobile_menu li div img {
  position: absolute;
  top: -0.4vw;
  width: 68vw;
}
@media only screen and (max-width: 1023px) {
  nav #mobile_menu li div img {
    width: 1200px;
  }
}
nav #mobile_menu li div #nav_sp_one {
  left: -0.4vw;
}
@media only screen and (max-width: 1023px) {
  nav #mobile_menu li div #nav_sp_one {
    left: -5px;
  }
}
nav #mobile_menu li div #nav_sp_two {
  left: -3.2vw;
}
@media only screen and (max-width: 1023px) {
  nav #mobile_menu li div #nav_sp_two {
    left: -50px;
  }
}
nav #mobile_menu li div #nav_sp_three {
  left: -5.5vw;
}
@media only screen and (max-width: 1023px) {
  nav #mobile_menu li div #nav_sp_three {
    left: -99px;
  }
}
nav #mobile_menu li div #nav_sp_four {
  left: -7.8vw;
}
@media only screen and (max-width: 1023px) {
  nav #mobile_menu li div #nav_sp_four {
    left: -139px;
  }
}
nav #mobile_menu li div #nav_sp_five {
  left: -10.5vw;
}
@media only screen and (max-width: 1023px) {
  nav #mobile_menu li div #nav_sp_five {
    left: -182px;
  }
}
nav #mobile_menu li div #nav_sp_six {
  left: -13vw;
}
@media only screen and (max-width: 1023px) {
  nav #mobile_menu li div #nav_sp_six {
    left: -226px;
  }
}
@media only screen and (max-width: 1023px) {
  nav #mobile_menu li {
    padding: 5px;
  }
}
footer #top_footer {
  background-color: #DFDFDF;
  padding-bottom: 35px;
  padding-top: 35px;
}
@media only screen and (min-width: 1100px) {
  footer #top_footer #cta_section {
    margin: -28px auto -29px;
    transform: scale(0.5);
  }
}
@media only screen and (max-width: 1099px) {
  footer #top_footer #cta_section .cta-2 {
    display: none;
  }
}
@media only screen and (max-width: 1099px) {
  footer #top_footer #cta_section span {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 600px) {
  footer #top_footer #top_footer_container {
    border: 2px #fff solid;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 5px;
    padding-top: 0px;
    max-width: 840px;
  }
}
@media only screen and (min-width: 1400px) {
  footer #top_footer #top_footer_container {
    width: 50%;
  }
}
footer #top_footer #top_footer_container a {
  color: #333;
}
footer #top_footer #top_footer_container a:hover {
  color: #3b9ac6;
}
footer #top_footer h2 {
  text-align: center;
  color: #204673;
  padding-bottom: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  margin-top: 5px;
}
@media only screen and (min-width: 1400px) {
  footer #top_footer h2 {
    font-size: 38px;
  }
}
footer #top_footer #base_corp_address {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}
@media only screen and (min-width: 600px) {
  footer #top_footer #base_corp_address .break-mobile-footer {
    display: none;
  }
}
footer #top_footer #base_corp_address .footer-top-line-break {
  padding-left: 10px;
  padding-right: 10px;
}
@media only screen and (max-width: 599px) {
  footer #top_footer #base_corp_address .footer-top-line-break {
    display: none;
  }
}
footer #bottom_footer {
  background: #274476;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  color: #fff;
  margin-top: 18px;
  font-weight: 100;
  letter-spacing: 1px;
}
@media only screen and (max-width: 525px) {
  footer #bottom_footer {
    font-weight: 200;
  }
}
footer #bottom_footer > div {
  width: 80%;
  margin-left: 10%;
  font-size: 11px;
  padding-bottom: 21px;
}
footer #bottom_footer > div > .translator {
  display: inline-block;
  transform: translateY(20px);
  width: auto;
  margin-left: 0;
}
@media only screen and (min-width: 600px) {
  footer #bottom_footer .break-mobile-footer {
    display: none;
  }
}
footer #bottom_footer a {
  color: #fff;
  text-decoration: none;
}
footer #bottom_footer #LauncherTranslatePhrase {
  margin-left: 0;
}
footer #bottom_footer #LauncherTranslatePhrase:hover {
  background-color: #fff !important;
  color: #3B9AC6 !important;
}
footer #bottom_footer #LauncherLogo {
  margin-left: 0;
}
#root #mobile_page_container {
  height: auto;
}
#root #mobile_page_container #mobile_container {
  height: auto;
}
#root #mobile_page_container #mobile_container .first-page {
  height: auto !important;
}
nav {
  background: unset;
  position: absolute;
  top: 0;
}
nav #logo_navbutton_container {
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  nav #logo_navbutton_container #header_logo {
    width: 60%;
    max-width: 450px;
    margin-top: 50px;
  }
}
nav #close_menu {
  max-width: 40px;
  height: 40px;
  padding: 5px;
  position: absolute;
  right: 10px;
  top: 10px;
}
@media (min-width: 1100px) {
  nav #close_menu {
    top: 80px;
  }
}
nav #close_menu svg {
  width: 100%;
}
nav #close_menu svg path {
  fill: #fff;
}
nav #mobile_menu {
  background-color: #274476;
  width: 100%;
  max-width: 300px;
  position: fixed;
  right: -100%;
  height: 100vh;
  top: 0;
  flex-wrap: wrap;
  transition: right 0.4s ease;
  display: flex;
  align-content: space-around;
}
@media (min-width: 1440px) {
  nav #mobile_menu {
    width: 17%;
    max-width: unset;
  }
}
nav #mobile_menu ul {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  padding-left: 20px;
  width: 90%;
}
@media (min-width: 1100px) {
  nav #mobile_menu ul {
    margin-top: 120px;
  }
}
nav #mobile_menu li {
  height: auto;
  margin-top: 20px;
}
nav #mobile_menu li div {
  height: 40px;
  max-width: 40px;
  width: 100%;
  background-color: #fff;
  border-radius: 50%;
}
nav #mobile_menu li div svg {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  padding: 8px;
}
nav #mobile_menu li a {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
}
nav #mobile_menu li a:after {
  display: none;
}
.bottom-links {
  width: 80%;
  margin: auto;
  text-align: center;
}
.bottom-links p {
  color: #fff;
  font-family: 'Oswald', sans-serif;
  text-align: center;
  font-weight: 300;
  font-size: 20px;
}
.icons path {
  fill: #B31B1B;
}
#culture_hero_section {
  position: relative;
}
.navbar-toggle {
  margin-top: 0 !important;
  background-color: #B31B1B;
  border-radius: 50%;
  margin-right: 40px;
}
.navbar-toggle {
  padding: 10px;
  position: relative;
}
.navbar-toggle .icon-bar {
  background-color: #fff;
  width: 16px;
}
.slide {
  display: none;
}
.slide.active {
  display: block;
  animation: fade 1.4s ease forwards;
}
#homepage_text {
  width: 80%;
  margin: auto;
}
.header-text {
  background-color: rgba(32, 70, 115, 0.66);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px;
}
.header-text h1 {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  margin: 0;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 19px;
}
@media (min-width: 768px) {
  .header-text h1 {
    font-size: 30px;
  }
}
@media (min-width: 1100px) {
  .header-text h1 {
    font-size: 51px;
  }
}
#communities {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1390px;
  margin: 40px auto;
}
#communities .card-images {
  height: 450px;
}
#communities .card-images img {
  height: 100%;
  object-fit: cover;
}
.icon-link {
  display: inline-block;
  max-width: 30px;
  background-color: #fff;
  padding: 5px;
  border-radius: 50%;
  transition: transform 0.3s ease;
  cursor: pointer;
  margin: 0 3px;
}
.icon-link:hover {
  transform: scale(1.1);
}
.icon-link:hover .icon path {
  fill: #B31B1B;
}
.icon-link .icon {
  vertical-align: middle;
  width: 100%;
  fill: #274476;
}
html {
  scroll-behavior: smooth;
}
.card {
  max-width: 450px;
  margin-bottom: 20px;
  background-color: #F1F2F2;
}
.home-card-address p {
  color: #274476;
  font-family: 'Montserrat', sans-serif;
}
#homepage_text h2 {
  text-align: center;
  color: #B31B1B;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 45px;
  margin: 40px 0;
}
#homepage_text p {
  color: #274476;
  font-family: 'Montserrat', sans-serif;
  font-size: 21px;
  text-align: center;
  max-width: 1300px;
  margin: auto;
  margin-bottom: 30px;
}
.eho {
  display: inline-block;
  max-width: 30px;
  vertical-align: middle;
  margin: 0 3px;
  width: 100%;
}
.eho path {
  fill: #fff;
}
.eho .cls-1 {
  fill: #fff;
}
.footer-text {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  margin: 0 10px;
}
.footer-text span {
  font-family: 'Montserrat', sans-serif;
}
.footer-text a {
  font-family: 'Montserrat', sans-serif;
}
#handicap {
  display: inline-block;
  max-width: 25px;
  vertical-align: middle;
  margin: 0 3px;
}
#handicap path {
  fill: #fff;
}
.navbar-toggle {
  display: inline-block !important;
}
#logo_navbutton_container {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}
.home-card-community-info {
  margin: 30px;
  text-align: center;
}
.home-card-community-info h4 {
  color: #204673;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 30px;
}
header nav {
  position: relative;
  background-color: #fff;
}
@media (min-width: 768px) {
  header nav {
    position: absolute;
    background: unset;
  }
}
.btns {
  background-color: #B31B1B;
  display: block;
  padding: 10px 25px;
  max-width: 200px;
  text-align: center;
  margin: 20px auto;
  font-family: 'Montserrat', sans-serif;
  color: rgba(255, 255, 255, 0.85);
  font-size: 20px;
  text-decoration: none;
  transition: all 0.2s ease;
}
.btns:hover {
  background-color: #204673;
  color: rgba(255, 255, 255, 0.85);
  border-radius: 40px;
  font-weight: 700;
}
.btns:focus {
  color: rgba(255, 255, 255, 0.85);
}
footer {
  background: #274476;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px;
}
footer .top-footer {
  width: 100%;
  text-align: center;
}
@media (min-width: 1100px) {
  footer .top-footer {
    width: 50%;
  }
}
footer .top-footer img {
  max-width: 300px;
}
footer #bottom_footer {
  width: 100%;
}
@media (min-width: 1100px) {
  footer #bottom_footer {
    width: 50%;
  }
}
.scroll_icon {
  text-align: center;
  display: none;
}
@media (min-width: 1280px) {
  .scroll_icon {
    position: absolute;
    display: block;
    bottom: 12%;
    left: 0;
    right: 0;
    cursor: pointer;
  }
}
.scroll_icon:hover .mouse_icon {
  transform: scaleY(0.6);
  opacity: 0;
}
.scroll_icon:hover .scroll-hover {
  opacity: 1;
}
.scroll_icon .mouse_icon {
  transform-origin: bottom;
  transition: transform 0.2s, opacity 0.2s ease;
}
@media (min-width: 1280px) {
  .scroll_icon .mouse_icon {
    position: absolute;
    bottom: 0;
  }
}
.scroll_icon .scroll-hover {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  transition: opacity 0.2s ease;
}
.scroll_icon .scroll-hover g rect {
  fill: #B31B1B;
}
.scroll_icon .scroll-hover #Rectangle_1638 {
  fill: #fff;
}
.scroll_icon .scroll-hover #Rectangle_1637 {
  stroke: unset;
}
.scroll_icon .scroll-hover #chevron-small-down {
  fill: #fff;
}
@media (min-width: 1280px) {
  .scroll_icon .scroll-hover {
    position: absolute;
    bottom: 0;
    left: unset;
    right: unset;
  }
}
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
